@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./block-global-styles.css');
@import url('./locale-styles.css');

.main-sized-wrapper.carousel-wrapper {
  @apply px-0 sm:px-8 md:px-0;
}

.main-sized-wrapper .carousel-title {
  @apply px-5 sm:px-0;
}
.main-sized-wrapper {
  @apply w-full m-auto;
}

.main-sized-wrapper {
  width: 100%;
  @apply px-[22px];
  overflow: hidden;
}

input:-internal-autofill-selected {
  background-color: #fff !important;
}

@screen md {
  .main-sized-wrapper {
    @apply px-0;
  }
}

@screen md {
  .main-sized-wrapper {
    max-width: calc(884px - 4px);
  }
}

@screen lg {
  .main-sized-wrapper {
    max-width: calc(1240px - 60px);
  }
}

@media (min-width: 1336px) {
  .main-sized-wrapper {
    max-width: calc(1336px - 16px);
  }
}

@screen xl {
  .main-sized-wrapper {
    max-width: calc(1584px - 140px);
  }
}

.ai-answer-wrapper {
  a {
    @apply text-blue-700;
  }
}
